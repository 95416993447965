import * as Turbo from "@hotwired/turbo" // window.Turbo is set automatically
import * as TurboShadow from "turbo-shadow"

Turbo.StreamActions.update_attributes = function() {
//  batch(() => {
    this.targetElements.forEach(targetElement => {
      const newEl = this.templateContent.children[1]
      this.templateContent.children[0].textContent.split(",").forEach(attrName => {
        const attr = newEl.getAttribute(attrName)
        if (attr) {
          targetElement.setAttribute(attrName, attr)
        } else {
          targetElement.removeAttribute(attrName)
        }
      })
    })
//  })
}

Turbo.StreamActions.take_down_paywall = function() {
  const container = this.targetElements[0]
  const paywalledContents = container.querySelectorAll("paywalled-content")
  const newContents = this.templateContent.querySelectorAll("paywalled-content")
  newContents.forEach((newContent, index) => {
    const content = paywalledContents[index]
    if (content) {
      const filteredChildren = [...newContent.children].filter(node => !node.hasAttribute("shadowrootmode"))
      content.replaceChildren(...filteredChildren)
      for (const attr of newContent.attributes) {
        content.setAttribute(attr.name, attr.value)
      }
    }
  })
}

window.bypassTurboTransition = true
import "./turbo_transitions.js"

import { registerIconLibrary } from "@shoelace-style/shoelace/dist/utilities/icon-library.js"
registerIconLibrary("tabler", {
  resolver: name => `https://esm.sh/@tabler/icons@2.10.0/icons/${name}.svg`
})
import "@shoelace-style/shoelace/dist/components/alert/alert.js"
import "@shoelace-style/shoelace/dist/components/icon/icon.js"
import "@shoelace-style/shoelace/dist/components/breadcrumb/breadcrumb.js"
import "@shoelace-style/shoelace/dist/components/breadcrumb-item/breadcrumb-item.js"
import "@shoelace-style/shoelace/dist/components/drawer/drawer.js"

// Import styles entry
import "$styles/index.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css,heartml}"

// Web component to process incoming Turbo Streams
import "./turbo-async.js"
