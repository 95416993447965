
          var import_meta_document = new DocumentFragment()
        
          
  import { HeartElement } from "heartml"
  import { ShadowRootable } from "turbo-shadow"

  class FooterPresence extends ShadowRootable(HeartElement) {
    static properties = {
      accountId: { attribute: "accountid" },
    }

    static declarativeEffects = {
      light: true,
      shadow: true
    }

    static queries = {
      shadow: {
        turboAsync: "turbo-async"
      }
    }

    static {
      this.define("footer-presence")
    }

    async connectedCallback() {
      await this.shadowRootAttached
      super.connectedCallback()
    }

    start() {
      this.accountid = 0
    }

    sync() {
      this.turboAsync.fetch()
    }
  }

  document.documentElement.addEventListener("turbo:load", () => {
    document.querySelector("footer-presence").sync()
  })

          export default import_meta_document
        