export class TurboAsyncElement extends HTMLElement {
  static {
    if (!customElements.get("turbo-async")) {
      customElements.define("turbo-async", this)
    }
  }

  constructor() {
    super()
    this.attachShadow({ mode: "open" })
    this.shadowRoot.innerHTML = `<slot name="loading"></slot>`
  }

  connectedCallback() {
    if (!this.hasAttribute("done")) this.fetch()
  }

  async fetch() {
    this.removeAttribute("errorstatus")
    const url = this.getAttribute("src")

    const request = await fetch(url, {
      headers: { "Content-Type": "text/vnd.turbo-stream.html" }
    })

    if (request.ok) {
      const response = await request.text()
      window.Turbo.renderStreamMessage(response)

      this.shadowRoot.innerHTML = `<slot name="done"></slot>`
      this.setAttribute("done", "")
    } else {
      this.shadowRoot.innerHTML = `<slot name="error"></slot>`
      this.removeAttribute("done")
      this.setAttribute("errorstatus", request.status)
    }
  }
}
