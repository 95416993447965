import "data:text/css,account-drawer%20:is(p,%20li):last-of-type%20%7B%0A%20%20margin-block-end:%200;%0A%7D%0A%0Aaccount-drawer%20.button%20%7B%0A%20%20display:%20block;%0A%7D%0A%0Aaccount-drawer%20ul%20%7B%0A%20%20margin:%200%20!important;%0A%20%20padding:%200;%0A%20%20list-style-type:%20none;%0A%7D%0A%0Aaccount-drawer%20li%20%7B%0A%20%20margin-block-end:%20var(--size-4);%0A%7D%0A%0Aaccount-drawer%20p%20%7B%0A%20%20font-size:%20var(--font-size-0);%0A%7D%0A"

          var import_meta_document = new DocumentFragment()
        
          
  import { HeartElement } from "heartml"
  import { ShadowRootable } from "turbo-shadow"

  class AccountDrawer extends ShadowRootable(HeartElement) {
    static properties = {
      open: {},
      accountId: { attribute: "accountid" },
      firstName: { attribute: "firstname" },
    }

    static declarativeEffects = {
      light: true,
      shadow: true
    }

    static declarativeEvents = ["mousedown", "click"]

    static queries = {
      shadow: {
        turboAsync: "turbo-async"
      }
    }

    static {
      this.define("account-drawer")
    }

    start() {
      this.open = false
      this.accountid = 0
      this.firstname = ""
    }

    async connectedCallback() {
      await this.shadowRootAttached

      if (!this.resumed) {
        this.addEventListener("focus", event => {
          if (this.mouseEventTriggered) return;
          if (event.composedPath()[0].id = "nav-tab") {
            //alert("focusing!")
            console.log(event)
            this.open = true
          }
        })
      }

      super.connectedCallback()
    }

    handleMousedown(event) { // annoying fix needed for Firefox
      this.mouseEventTriggered = true
      setTimeout(() => {
        this.mouseEventTriggered = false
      }, 25)
      return
    }

    handleClick(event) {
      if (event.target.closest("#nav-tab")) {
        this.open = !(this.open)
      }
    }

    sync() {
      this.turboAsync.fetch()
    }
  }

  document.documentElement.addEventListener("click", e => {
    const drawer = document.querySelector("account-drawer")
    if (drawer.open && !e.target.closest("account-drawer")) {
      drawer.open = false
    }
  })

  document.documentElement.addEventListener("turbo:load", () => {
    const drawer = document.querySelector("account-drawer")
    drawer.sync()
    if (drawer.open) setTimeout(() => drawer.open = false, 500)
  })

          export default import_meta_document
        