import "data:text/css,%0A%20%20%0A%20%20notes-drawer%20%3E%20sl-drawer::part(body)%20%7B%0A%20%20%20%20padding-block-start:%200;%0A%20%20%7D%0A%0Anotes-drawer%20%3E%20sl-drawer%20%7B%0A%20%20--size:%20clamp(30rem,%2088dvh,%2040rem);%0A%7D%0A%0Anotes-drawer%20div%5Bcontenteditable=%22true%22%5D%20%7B%0A%20%20min-height:%2012rem;%0A%20%20max-height:%20clamp(12rem,%2040dvh,%2025rem);%0A%20%20overflow:%20auto;%0A%7D%0A%0Anotes-drawer%20%3E%20sl-drawer%20%3E%20p%20%7B%0A%20%20font-size:%2080%25;%0A%20%20margin-block-end:%201.2em;%0A%7D%0A%0Anotes-drawer%20p%5Bsave-message%5D%20%7B%0A%20%20float:%20left;%0A%20%20color:%20darkgreen;%0A%20%20margin-top:%20var(--size-1);%0A%7D%0A"

          var import_meta_document = new DocumentFragment()
        
          
  class NotesDrawer extends HTMLElement {
    static {
      customElements.define("notes-drawer", this)
    }

    handleChange(event) {
      clearTimeout(this.debouncer)
      this.debouncer = setTimeout(() => {
        console.info(this.querySelector("#notes-input").value)
        const saved = this.querySelector("p[save-message]")
        const form = this.querySelector("form")

        fetch(form.action, { method: "post", body: new FormData(form)})

        saved.hidden = false
        setTimeout(() => {
          saved.hidden = true
        }, 3000)
      }, 1000)
    }

    connectedCallback() {
      const drawer = this.querySelector("sl-drawer")
      const openButton = drawer.nextElementSibling;
      const closeButton = drawer.querySelector('button[slot="footer"]')

      openButton.addEventListener('click', () => drawer.show())
      closeButton.addEventListener('click', () => drawer.hide())

      customElements.whenDefined("rhino-editor").then(() => {
        // we don't want to auto-save before anything's actually happened
        setTimeout(() => {
          this.addEventListener("rhino-change", this.handleChange.bind(this))
        }, 1000)

        this.addEventListener("rhino-file-accept", event => event.preventDefault())
      })

      // ** This crashes the editor, maybe there's a fix... **
      // customElements.whenDefined("rhino-editor").then(() => {
      //   this.querySelectorAll("rhino-editor").forEach((rhinoEditor) => {
      //     // configuring the starter kit
      //     rhinoEditor.starterKitOptions = { ...rhinoEditor.starterKitOptions, rhinoAttachment: false }
      //   })
      // })
    }
  }

          export default import_meta_document
        