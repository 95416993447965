
          var import_meta_document = new DocumentFragment()
        
          
  customElements.define("paywalled-content", class extends HTMLElement {
    static observedAttributes = ["open"]

    setOpenSlots(openValue) {
      if (openValue) {
        this.shadowRoot.querySelector("slot").removeAttribute("name")
      } else {
        this.shadowRoot.querySelector("slot").name = "public"
      }
    }

    attributeChangedCallback(name, oldValue, newValue) {
      if (!this.shadowRoot) return; // can happen on elements which are DSDed open to begin with

      this.setOpenSlots(name === "open" && newValue !== null)
    }
  })

          export default import_meta_document
        